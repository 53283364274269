import { checkoutDetail } from 'api';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchBill = createAsyncThunk(
  "checkoutDetail/fetchBill",
  async ({ id, billIdSql }: { id: string; billIdSql: string }) => {
    try {
      const { data }: { data: CheckoutDetailType } =
        await checkoutDetail.getCheckoutBill(
          id
            ? {
                billId: id,
              }
            : {
                billIdSql: billIdSql,
              }
        );

      let newListCampaign = [];
      if (data?.listCampaignApply?.length)
        newListCampaign = data.listCampaignApply
          .map((item) => ({
            ...item,
            selected: data?.chosenCampaign
              ? data?.chosenCampaign.includes(item.campaignId) && item.isValid
              : false,
            selectBooking: data?.chosenCampaign
              ? data?.chosenCampaign.includes(item.campaignId)
              : false,
          }))
          .sort((x, y) =>
            x.selectBooking === y.selectBooking ? 0 : x.selectBooking ? -1 : 1
          );

      return {
        ...data,
        defaultService: data.serviceIds || [],
        defaultProduct: data.productIds || [],
        serviceIds: JSON.stringify(data.serviceIds || []),
        productIds: JSON.stringify(data.productIds || []),
        listCampaignApply: newListCampaign,
      };
    } catch (error) {
      return error.response.data;
    }
  }
);

const innitState = {
  bill: null as CheckoutDetailType,
  messageGetBill: null as string,
  openModalTotalBill: false as boolean,
};

const checkoutDetailSlice = createSlice({
  name: "checkout",
  initialState: innitState,
  reducers: {
    resetStateCheckoutDetail: (state) => {
      state.bill = null;
      state.messageGetBill = null;
    },
    setBillData: (state, action) => {
      state.bill[action.payload.key] = action.payload.value;
    },
    setOpenModalTotalBill: (state, action) => {
      state.openModalTotalBill = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBill.fulfilled, (state, action) => {
      state.bill = action.payload;
    });
  },
});
const { reducer: checkoutDetailReducer, actions } = checkoutDetailSlice;
export const { resetStateCheckoutDetail, setBillData, setOpenModalTotalBill } =
  actions;

export default checkoutDetailReducer;
