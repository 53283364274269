import axios from 'axios';
import { SSO } from '@30shine-fe/sso-erp';

const http = require('http');
const https = require('https');

const sso = new SSO(process.env.REACT_APP_ENV || 'test');

export default function getInstanceAxios(baseAPI) {
	const instance = axios.create({
		baseURL: baseAPI,
		httpAgent: new http.Agent({ keepAlive: true }),
        httpsAgent: new https.Agent({ keepAlive: true }),
	});
	instance.interceptors.request.use(
		(config) => {
			const AccessToken = localStorage.getItem('AccessToken');
			config.headers = {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: AccessToken ? 'Bearer ' + AccessToken : '',
			};
			return config;
		},
		function (error) {
			return Promise.reject(error);
		}
	);
	instance.interceptors.response.use(
		function (response) {
			try {
				if (response?.status !== 200 && response?.status !== 204 ) return Promise.reject(response);
				return response;
			} catch (error) {
				return Promise.reject(error);
			}
		},
		async (error) => {
			if (error?.response?.status === 401) {
				localStorage.clear();
				try {
					let data = await sso.refreshToken();
					if (data?.AccessToken) {
						localStorage.setItem('AccessToken', data.AccessToken);
						localStorage.setItem('RefreshToken', data.RefreshToken);
						localStorage.setItem('IdToken', data.IdToken);
					}
				} catch (error) {
					sso.ssoLogout();
				}
			}
			return Promise.reject(error);
		}
	);
	return instance;
}
