import { users } from 'api';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchPermissionRouting = createAsyncThunk('user/fetchPermissionRouting', async () => {
	const response = await users.getPermissionUser({
		domain: process.env.REACT_APP_DOMAIN_PERMISSION,
	});
	const { data, status } = response;
	if (status === 200) {
		return data;
	}
	return undefined;
});

const innitState = {
	listRoutingPermission: null as RoutingPermissionType[],
};

const permissionSlice = createSlice({
	name: 'permission',
	initialState: innitState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchPermissionRouting.fulfilled, (state, action) => {
			state.listRoutingPermission = action.payload;
		});
	},
});

const { reducer: permissionReducer } = permissionSlice;
export default permissionReducer;
