import { RequestManager } from 'utils';

const getListStaffReturnProduct = () => {
	return RequestManager.checkoutv2.get('/api/product-return/list-staff');
};

const getListProductReturn = (params?:any) => {
	return RequestManager.checkoutv2.get('/api/product-return', { params });
};

const returnProduct = (request?: any) => {
	return RequestManager.checkoutv2.post('/api/product-return', request);
};

const sendOtp = (body?: any) => {
	return RequestManager.otp.post('/otp/send-transaction', body);
};

export default {
	getListStaffReturnProduct,
	getListProductReturn,
	returnProduct,
	sendOtp
};
