import { users } from 'api';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchUser = createAsyncThunk('user/fetchUser', async () => {
	const response = await users.getUser();
	const { data, status } = response;
	if (status === 200) {
		return data;
	}
	return undefined;
});

const innitState = {
	userInfo: undefined as UserType,
	urlAccess: [],
};

const userSlice = createSlice({
	name: 'user',
	initialState: innitState,
	reducers: {
		setUrlAccess: (state, action) => {
		  state.urlAccess = action.payload
		}
	  },
	extraReducers: (builder) => {
		builder.addCase(fetchUser.fulfilled, (state, action) => {
			state.userInfo = action.payload;
		});
	},
});

const { reducer: userReducer, actions } = userSlice;
export const { setUrlAccess } = actions
export default userReducer;
