import { createSlice } from "@reduxjs/toolkit";

const innitState = {
  tipProduct: undefined,
  tipDefault: undefined,
};

const tipSlice = createSlice({
  name: "tip",
  initialState: innitState,
  reducers: {
    set: (state, action) => {
      state.tipProduct = action.payload;
    },
    setDefault: (state, action) => {
      state.tipDefault = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

const { reducer: tipReducer, actions } = tipSlice;
export const { set, setDefault } = actions;
export default tipReducer;
