import { RequestManager } from "utils";
import { listMenu } from "utils/requestManager/Author";

const getCheckoutBill = (params?: any) => {
  return RequestManager.checkoutMSA.get("api/v1/checkout", { params });
};

// const getCustomerDetail = (params?: any) => {
//   return RequestManager.timeline.get("/api/customer/customer-detail", {
//     params,
//   });
// };
const getCustomerDetail = (params?: any) => {
  return RequestManager.CustomerViewService.get("/api/v1/web-customer/info", {
    params,
  });
};

const getCustomerGetCustomerOverView = (params?: any) => {
  return RequestManager.CustomerViewService.get(
    `api/v1/web-customer/overview`,
    { params }
  );
};

const createProductBill = (params?: any) => {
  return RequestManager.checkoutServiceMSA.post(
    "/api/v1/checkout/billing",
    params
  );
};

const getMemberIdsConfig = () => {
  return RequestManager.checkout.get("/api/member/get-member-config");
};

const getTodayCustomerBill = (params) => {
  return RequestManager.checkoutMSA.get(
    "/api/v1/checkout/today-customer-bill",
    { params }
  );
};

const getSalonServices = (params) => {
  return RequestManager.MSAProductService.get("/api/v1/service/list", {
    params,
  });
};

const getSalonProducts = (params) => {
  return RequestManager.MSAProductService.get("/api/v1/product/list", {
    params,
  });
};

const getStaffWorkTime = (params) => {
  return RequestManager.Timekeeping.get(
    "/flow-timekeeping-view/get-list-timekeeping-checkout",
    {
      params,
    }
  );
};

const getStaff = (params) => {
  return RequestManager.timeline.get("/api/get-staff/get-staff", { params });
};

const getAllStaff = (params) => {
  return RequestManager.timeline.get("/api/get-staff/get-all-staff-in-salon", {
    params,
  });
};

const getSuggestActions = (params) => {
  return RequestManager.customerInsight.get("/api/v1/actions", { params });
};

const getSalonBillInfo = (params) => {
  return RequestManager.checkout.get("/api/salon/salon-bill-info", { params });
};

const getConfig = (params) => {
  return RequestManager.Stynext.get("/api/config", { params });
};

const applyCampaign = (params) => {
  return RequestManager.Campaign.post(
    "/api/campaign/v2/apply-campaign-msa",
    params
  );
};

const updateStaffBill = (params) => {
  return RequestManager.checkoutServiceMSA.put(
    "/api/v1/checkout/assigned-st-sk",
    params
  );
};

const getCampaignCode = (params) => {
  return RequestManager.Campaign.post("/api/campaign/v2/voucher-msa", params);
};

const getListBillPending = (params) => {
  return RequestManager.checkoutMSA.get("/api/v1/checkout/list", { params });
};

const completedBill = (params) => {
  return RequestManager.checkoutServiceMSA.put(
    "/api/v1/checkout/complete-bill",
    params
  );
};

const getPermissions = (staffId) => {
  return RequestManager.checkout.get(
    `/api/bill/permissions?staffId=${staffId}`
  );
};

const getListAction = (url: string) => {
  return listMenu.get(`/api/menu-action?path=${url}`)
};

const getInventoryProduct = ({ salonId, mapIdProduct }) => {
  return RequestManager.Inventory.get(
    `/api/iv-inventory-current/get-product-current?salonId=${salonId}&listProductId=${mapIdProduct}`
  );
};

const checkoutPaymentMomo = (momoLogBody) => {
  return RequestManager.checkout.put(`/api/checkout/momo-error`, momoLogBody);
};

const getOTP = (body) => {
  return RequestManager.otp.post(`/otp/send-transaction`, body);
};

const getDetailCampaign = (params) => {
  return RequestManager.Campaign.get(`/api/campaign/v2/detail`, { params });
};

const requestRatingStatus = (params) => {
  return RequestManager.TimelineSocket.post(`/api/pushNotice/socketIO`, params);
};

const billConfirm = (params) => {
  return RequestManager.checkoutServiceMSA.post(
    `/api/v1/checkout/confirm`,
    params
  );
};

const getServiceChangeHistory = (params) => {
  return RequestManager.checkoutMSA.get(`/api/v1/checkout/bill-log`, {
    params,
  });
};

const gotIt = (params) => {
	return RequestManager.checkoutServiceMSA.post(`/api/v1/gotit`, params);
};

const deleteGotIt = (params) => {
	return RequestManager.checkoutServiceMSA.post(`/api/v1/gotit/delete`, params);
};

const getCampaign = (params) => {
	return RequestManager.Campaign.get(`/api/campaign/v2/checkout/bill/${params}`);
};

const getShopBillDetail = (params) => {
	return RequestManager.Shopbill.get(`/api/v1/web/checkouts/fulfillment/${params}`);
};

const getTipInfo = (salonId, serviceIds) => {
  return RequestManager.checkoutServiceMSA.post(
    `/api/v1/checkout/product-tip/${salonId}`,
    serviceIds
  );
};

export default {
  getCustomerGetCustomerOverView,
  getCheckoutBill,
  getCustomerDetail,
  createProductBill,
  getMemberIdsConfig,
  getTodayCustomerBill,
  getConfig,
  getSalonServices,
  getSalonProducts,
  getStaff,
  getAllStaff,
  getSuggestActions,
  getSalonBillInfo,
  applyCampaign,
  updateStaffBill,
  getCampaignCode,
  getListBillPending,
  completedBill,
  getPermissions,
  getInventoryProduct,
  checkoutPaymentMomo,
  getOTP,
  getDetailCampaign,
  requestRatingStatus,
  billConfirm,
  getServiceChangeHistory,
  getStaffWorkTime,
  gotIt,
  deleteGotIt,
  getListAction,
  getCampaign,
  getShopBillDetail,
  getTipInfo,
};
