import React, { createRef, useEffect, useRef, useState } from 'react';

const SingleOTPInput = ({
	value,
	focus,
	isLastChild,
	separator,
	onChange,
	onPaste,
	onFocus,
	onKeyDown,
}) => {
	const inputRef = useRef(null);

	const handleOnChange = (e) => {
		let newVal = e.target.value;
		if (newVal.length > 1) {
			newVal = newVal.slice(0, 1);
		}
		onChange(newVal);
	};

	const isCodeNumeric = (key) => {
		var isNumber = /^[0-9]$/i.test(key);
		return isNumber;
	};

	const handleOnKeyDown = (event) => {
		// Only allow characters 0-9, DEL, Backspace and Pasting
		const keyevent = event || window.event;
		const charCode = keyevent.which ? keyevent.which : keyevent.keyCode;
		if (isCodeNumeric(keyevent.key) || charCode === 8 || charCode === 86 || charCode === 46) {
			onKeyDown(event);
		} else {
			keyevent.preventDefault();
		}
	};

	const handleOnPaste = (event) => {
		onPaste(event);
	};

	const handleOnFocus = () => {
		if (inputRef.current) inputRef.current.focus();
		onFocus();
	};

	useEffect(() => {
		if (focus) {
			if (inputRef.current) inputRef.current.focus();
		}
	}, [focus]);

	return (
		<div className="input" style={{ display: 'flex', alignItems: 'center' }}>
			<input
				className={`${focus ? 'input__focus' : ''} `}
				type={'tel'}
				min="0"
				max="9"
				ref={inputRef}
				onChange={(e) => handleOnChange(e)}
				onKeyDown={handleOnKeyDown}
				onPaste={handleOnPaste}
				onFocus={handleOnFocus}
				value={value}
			/>
			{isLastChild && separator ? <span>{separator}</span> : null}
		</div>
	);
};

export default SingleOTPInput;
