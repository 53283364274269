import { useEffect } from 'react'
import { BrowserRouter, } from 'react-router-dom'
import { fetchUser } from 'redux/slices/userSlice'
import { useAppDispatch } from 'redux/store'
import DefaultRouter from 'routes/DefaultRouter'

function DefaultLayout() {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchUser())
  }, [])
  return (
    <BrowserRouter>
      <DefaultRouter />
    </BrowserRouter>
  )
}

export default DefaultLayout
