import { RequestManager } from 'utils';

// lấy thông tin chi tiết kh màn chi tiết bill
const getCustomerCheckoutDetail = (params?: any) => {
	return RequestManager.CustomerViewService.get('/api/v1/checkout/profile-checkout', {
		params,
	});
};

const getAddress = () => {
	return RequestManager.checkout.get('/api/customer/get-address-customer-info');
};
// update thông tin chi tiết kh màn chi tiết bill
const updateCustomer = (params) => {
	return RequestManager.CustomerViewService.put(
		'/api/v1/checkout/profile-checkout',
		params
	);
};

const getCardMemberByCardCode = (params) => {
	return RequestManager.customerInsight.get('/api/CardMember/GetCardInfoByCode', params);
};

const getCardMemberByCustomerId = (params) => {
	return RequestManager.customerInsight.get(`/api/CardMember/GetCardInfoByCustomerId?customerId=${params}`);
};

const checkCustomerMemberCard = (params) => {
	return RequestManager.customerInsight.get('/api/CardMember', {params});
};

const addCardMember = (params) => {
	return RequestManager.customerInsight.post('/api/CardMember', params);
};

export default {
	getCustomerCheckoutDetail,
	getAddress,
	updateCustomer,
	getCardMemberByCardCode,
	getCardMemberByCustomerId,
	addCardMember,
	checkCustomerMemberCard,
};
