import React, { useEffect, useState } from 'react';
import SingleOTPInput from './SingleOTPInput';
import './styles.scss';

// keyCode constants
const BACKSPACE = 8;
const LEFT_ARROW = 37;
const RIGHT_ARROW = 39;
const DELETE = 46;

const OTPInput = ({ scan, numInputs = 4, inputClasses, isInputNum, onChange, separator, valueOTP }) => {
	const [activeInput, setActiveInput] = useState(0);
	const [otp, setOtp] = useState([]);

	const handleOnFocus = (index) => {
		setActiveInput(index);
	};

	const handleOnBlur = () => {
		// setActiveInput(activeInput - 1);
	};

	// Helper to return OTP from input
	const checkFilledAllInputs = () => {
		if (otp.join('').length === numInputs) {
			return '';
		}
		return 'Wait until the user enters the required number of characters';
	};

	const focusInput = (input) => {
		setActiveInput(Math.max(Math.min(numInputs - 1, input), 0));
	};

	const focusNextInput = () => {
		focusInput(activeInput + 1);
	};

	const focusPrevInput = () => {
		focusInput(activeInput - 1);
	};

	const changeCodeAtFocus = (value) => {
		const newListOtp = otp.map((item, i) => {
			if (i === activeInput) {
				item = value;
			}
			return item;
		});
		setOtp(newListOtp);
		onChange(newListOtp.join(''));
		checkFilledAllInputs();
	};

	const handleOnPaste = (event) => {
		event.preventDefault();
		const pastedData = event.clipboardData
			.getData('text/plain')
			.slice(0, numInputs - activeInput)
			.split('');
		if (isInputNum && !pastedData.join('').match(/^\d+$/)) {
			return 'Invalid pasted data';
		}
		// Paste data from focused input onwards
		const currentCharsInOtp = otp.slice(0, activeInput);
		const combinedWithPastedData = currentCharsInOtp.concat(pastedData);
		const newOtp = combinedWithPastedData.slice(0, numInputs);
		setOtp(newOtp);
		focusInput(newOtp.length);
		onChange(newOtp.join(''));

		return checkFilledAllInputs();
	};

	const handleOnChange = (value) => {
		changeCodeAtFocus(value);
		focusNextInput();
	};

	const clearInput = () => {
		if (otp.length > 0) {
			onChange('');
		}
		setOtp([]);
		setActiveInput(0);
	};

	const setData = (otpString) => {
		if (otpString) {
			setOtp(otpString.split(''));
		}
	};

	// Handle cases of backspace, delete, left arrow, right arrow
	const handleOnKeyDown = (event) => {
		switch (event.keyCode) {
			case BACKSPACE:
				event.preventDefault();
				changeCodeAtFocus('');
				focusPrevInput();
				break;
			case DELETE:
				event.preventDefault();
				changeCodeAtFocus('');
				break;
			case LEFT_ARROW:
				event.preventDefault();
				focusPrevInput();
				break;
			case RIGHT_ARROW:
				event.preventDefault();
				focusNextInput();
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		const newListOtp = [];
		if (numInputs > 0) {
			for (let index = 0; index < numInputs; index++) {
				newListOtp.push('');
			}
			setOtp(newListOtp);
		}
	}, [numInputs]);

	useEffect(() => {
		if (scan) {
			const charList = scan.split('');
			setOtp(charList);
			focusInput(charList.length);
			onChange(charList.join(''));
		}
	}, [scan]);

	useEffect(() => {
		if (!valueOTP) {
			setOtp(['', '', '', '', '', '']);
		}
	}, [valueOTP]);

	return (
		<div className="otp-input">
			{otp.map((item, i) => {
				return (
					<SingleOTPInput
						key={i}
						value={item}
						focus={activeInput === i}
						isLastChild={item === numInputs - 1}
						separator={separator}
						onChange={(value) => handleOnChange(value)}
						onPaste={(e) => handleOnPaste(e)}
						onFocus={() => handleOnFocus(i)}
						onKeyDown={(event) => handleOnKeyDown(event)}
					/>
				);
			})}
		</div>
	);
};

export default OTPInput;
