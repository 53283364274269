import React, { useCallback, useEffect, useState } from 'react';
import { Layout, Dropdown, Button, Menu } from 'antd';
import {
	CaretDownOutlined,
	LogoutOutlined,
	MenuFoldOutlined,
	MenuUnfoldOutlined,
} from '@ant-design/icons';
import './styles.scss';
import MainRouter from '../routes/MainRouter';
import logo from 'images/logo.png';
import personIcon from 'images/person.png';
import { useMediaQuery } from 'react-responsive';
import { SSO } from '@30shine-fe/sso-erp';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { fetchUser } from 'redux/slices/userSlice';
import MainMenu from './MainMenu';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { orEmpty, orNull } from 'utils/selector';

const sso = new SSO(process.env.REACT_APP_ENV || 'test');
const { Header, Sider, Content } = Layout;

function DashboardLayout() {
	const isMinPC = useMediaQuery({
		query: '(max-width: 1550px)',
	});
	const isMobile = useMediaQuery({
		query: '(max-width: 1024px)',
	});
	const [collapsed, setCollapsed] = useState(isMinPC);
	// const [isShowSider, setIsShowSider] = useState(true);
	const { userInfo } = useAppSelector((state) => state.user);
	const dispatch = useAppDispatch();

	const logOut = async () => {
		localStorage.clear();
		sso.ssoLogout();
	};

	const menu = (
		<Menu>
			<Menu.Item key="1" icon={<LogoutOutlined />} onClick={logOut}>
				Đăng xuất
			</Menu.Item>
		</Menu>
	);

	const ComponentLayout = () => {
		const location = useLocation();

		const MainRouterComponent = useCallback(() => {
			return <MainRouter />;
		}, [userInfo]);

		// useEffect(() => {
		// 	if (location.pathname.split('/')[1] === 'checkout') {
		// 		setIsShowSider(false);
		// 	} else {
		// 		setIsShowSider(true);
		// 	}
		// }, [location]);

		return (
			<Layout id="layout">
				<Header className="layout__header">
					<div className="flex items-center">
						{/* <Link to={'/'} className="header__logo w-[84px]">
							<img src={logo} alt="" />
						</Link> */}
						{!collapsed ? (
							<MenuFoldOutlined
								style={{
									color: '#FFF',
									fontSize: 24,
									marginLeft: 10,
									cursor: 'pointer',
									display: isMobile? "none":""
								}}
								onClick={() => setCollapsed(!collapsed)}
							/>
						) : (
							<MenuUnfoldOutlined
								style={{
									color: '#FFF',
									fontSize: 24,
									marginLeft: 10,
									cursor: 'pointer',
									display: isMobile? "none":""
								}}
								onClick={() => setCollapsed(!collapsed)}
							/>
						)}
					</div>
					<Dropdown overlay={menu}>
						<Button className="header__account-button">
							<img src={personIcon} alt="" />
							<strong className="flex truncate max-w-[90px] md:max-w-max">
								<span className="hidden md:block"> Xin chào,</span>{' '}
								{orNull('fullName', userInfo)}
							</strong>
							<CaretDownOutlined color="white" />
						</Button>
					</Dropdown>
				</Header>
				<Layout className="layout__body">
					{/* {isShowSider ? ( */}
						<Sider
							className="body__sidebar"
							trigger={null}
							collapsible
							collapsed={collapsed}
						>
							<MainMenu />
						</Sider>
					{/* ) : null} */}
					<Content className="body__page">
						<MainRouterComponent />
					</Content>
				</Layout>
			</Layout>
		);
	};

	useEffect(() => {
		dispatch(fetchUser());
	}, []);

	return (
		<BrowserRouter>
			<ComponentLayout />
		</BrowserRouter>
	);
}

export default DashboardLayout;
