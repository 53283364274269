import { checkoutDetail, customerInsight } from 'api';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import getInstanceAxios from 'utils/requestManager/RequestS3Config';
import { convert } from 'utils';
import { formatPriceWithKCharacter } from 'utils/helpers/convert';

export const fetchMemberIdsConfig = createAsyncThunk('checkout/fetchMemberIdsConfig', async () => {
	try {
		const response = await checkoutDetail.getMemberIdsConfig();
		const { data, status } = response;
		if (status === 200) {
			return data;
		}
	} catch (error) {}
	return null;
});

export const fetchAddressConfig = createAsyncThunk('checkout/fetchAddressConfig', async () => {
	try {
		const response = await customerInsight.getAddress();
		const { data, status } = response;
		if (status === 200) {
			return data;
		}
	} catch (error) {}
	return null;
});

export const fetchSalonProduct = createAsyncThunk(
	'checkout/fetchSalonProduct',
	async (params: any) => {
		try {
			const res = await checkoutDetail.getSalonProducts(params);
			const response = res?.data || null;
            if (!response) return
			const { data, message } = response;
			const newData = data.map((item) => {
				let childProducts = [];
				if (item.mapIdProduct) {
					let cpis = item.mapIdProduct.split(',');
					const childProductIds = [...new Set(cpis)];
					childProductIds.map((i) => {
						const p = data.find((s) => s.id == i);
						if (p) {
							childProducts.push({
								...p,
								discount: 0,
								originalPrice: p ? p.price : 0,
								quantity: cpis.filter((id) => id === i).length,
								parentProductId: p ? item.id : 0,
							});
						}
					});
				}

				return {
					...item,
					name: item.name + ' - ' + formatPriceWithKCharacter(item.price),
					childProducts,
					comboReducedPrice:
						item.price -
						childProducts.reduce((total, s) => total + s.quantity * s.price, 0),
				};
			});				
			if (message === "Success") {
				return { [params.salonId]: newData };
			}
		} catch (error) {}
		return null;
	}
);

export const fetchSalonService = createAsyncThunk(
	'checkout/fetchSalonService',
	async (params: any) => {
		try {
			const res = await checkoutDetail.getSalonServices(params);
			const response = res?.data || null;
            if (!response) return
			const { data, message } = response;
			const newData = data.map((item) => {
				let childServices = [];
				if (item.mapIdService) {
					const childServiceIds = item.mapIdService.split(',');
					childServiceIds.map((i) => {
						const s = data.find((s) => s.id == i);
						if (s) {
							childServices.push({
								...s,
								discount: 0,
								originalPrice: s ? s.price : 0,
								isGuaranteeChildService:
									childServiceIds.length === 1 ? true : undefined,
								parentServiceId: s ? item.id : 0,
							});
						}
					});
				}
				return {
					...item,
					name: item.name + ' - ' + formatPriceWithKCharacter(item.price),
					childServices,
					comboReducedPrice:
						item.price - childServices.reduce((total, s) => total + s.price, 0),
				};
			});
			if (message === "Success") {
				return { [params.salonId]: newData };
			}
		} catch (error) {}
		return null;
	}
);

export const fetchAllStaffSalon = createAsyncThunk(
	'checkout/fetchAllStaffSalon',
	async (params: any) => {
		try {
			const response = await checkoutDetail.getAllStaff(params);
			const { data, status } = response;
			if (status === 200) {
				return { [params.salonId]: data };
			}
		} catch (error) {}
		return null;
	}
);

export const fetchOpenServiceLockReason = createAsyncThunk(
	'checkout/fetchOpenServiceLockReason',
	async () => {
		try {
			const response = await checkoutDetail.getConfig({ key: 'OpenServiceLockReason' });
			const { data, status } = response;
			if (status === 200 && data.length) {
				return [
					...data,
					{
						id: 0,
						description: null,
						key: 'OpenServiceLockReason',
						value: 'Lý do khác:',
						label: 'Lý do sửa dịch vụ checkout',
					},
				];
			}
			return [];
		} catch (error) {}
		return [];
	}
);

export const fetchListPaymentOnlineCard = createAsyncThunk(
	'checkout/fetchListPaymentOnlineCard',
	async () => {
		try {
			const response = await getInstanceAxios(process.env.REACT_APP_PAYMENT_CONFIG_URL).get(
				''
			);
			const { data, status } = response;
			if (status === 200) {
				return data;
			}
		} catch (error) {}
		return [];
	}
);

const innitState = {
	memberIdsConfig: null as ShinMemberIdsConfigType,
	listAddressSelect: null as AddressConfigType,
	listProductSalon: null as { [key: string]: ProductTye[] },
	listServiceSalon: null as { [key: string]: ServiceType[] },
	listStaffSalon: null as { [key: string]: StaffType[] },
	listOpenServiceLockReason: [] as ServiceLockReason[],
	listPaymentOnlineCard: [] as PaymentOnlineCard[],
	currentSalonId: null as number,
	permissions: null as PermissionType,
};

const checkoutSlice = createSlice({
	name: 'checkout',
	initialState: innitState,
	reducers: {
		setCurrentSalonId: (state, action) => {
			state.currentSalonId = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchMemberIdsConfig.fulfilled, (state, action) => {
			state.memberIdsConfig = action.payload;
		});
		builder.addCase(fetchAddressConfig.fulfilled, (state, action) => {
			state.listAddressSelect = action.payload;
		});
		builder.addCase(fetchSalonProduct.fulfilled, (state, action) => {
			state.listProductSalon = { ...state.listProductSalon, ...action.payload };
		});
		builder.addCase(fetchSalonService.fulfilled, (state, action) => {
			state.listServiceSalon = { ...state.listServiceSalon, ...action.payload };
		});
		builder.addCase(fetchAllStaffSalon.fulfilled, (state, action) => {
			state.listStaffSalon = { ...state.listStaffSalon, ...action.payload };
		});
		builder.addCase(fetchOpenServiceLockReason.fulfilled, (state, action) => {
			state.listOpenServiceLockReason = action.payload;
		});
		builder.addCase(fetchListPaymentOnlineCard.fulfilled, (state, action) => {
			state.listPaymentOnlineCard = action.payload;
		});
	},
});

const { reducer: checkoutReducer, actions } = checkoutSlice;
export const { setCurrentSalonId } = actions;
export default checkoutReducer;
