export default {
	randomFilename: function (ext = 'JPEG') {
		return (
			Math.random().toString(36).substring(2, 15) +
			Math.random().toString(36).substring(2, 15) +
			`.${ext}`
		);
	},
	currencyFormat: function (money) {
		const value = money ? money : '0';

		return parseFloat(`${value}`.split(',').join(''));
	},
	currencyFormatVND: function (money) {
		const value = money ? money : '0';
		const formatter = new Intl.NumberFormat('vi-VN', {
			style: 'currency',
			currency: 'VND',
			minimumFractionDigits: 0,
		});
		return formatter.format(value);
	},

	getStringRandom: function () {
		const result =
			Math.random().toString(36).substring(2, 15) +
			Math.random().toString(36).substring(2, 15);

		return result;
	},
	getSlug: function (str) {
		str = str.replace(/^\s+|\s+$/g, ''); // trim
		str = str.toLowerCase();

		// remove accents, swap ñ for n, etc
		// var from = "ÀÁẠẢÃẦẤẬẨÂĂẮẰẶẴÄÅĄàáâãäåąÒÓÔÕÕÖØòóôõöøÈÉÊËĘèéêëðęÇĆçćÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠŚšśŸÿýŽŹŻžźżŁłŃń·/_,:;";
		// var to = "AAAAAAAaaaaaaaOOOOOOOooooooEEEEEeeeeeeCCccDIIIIiiiiUUUUuuuuNnSSssYyyZZZzzzLlNn------";
		// for (var i = 0, l = from.length; i < l; i++) {
		//   str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
		// }

		var AccentsMap = [
			'aàảãáạăằẳẵắặâầẩẫấậ',
			'AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ',
			'dđ',
			'DĐ',
			'eèẻẽéẹêềểễếệ',
			'EÈẺẼÉẸÊỀỂỄẾỆ',
			'iìỉĩíị',
			'IÌỈĨÍỊ',
			'oòỏõóọôồổỗốộơờởỡớợ',
			'OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ',
			'uùủũúụưừửữứự',
			'UÙỦŨÚỤƯỪỬỮỨỰ',
			'yỳỷỹýỵ',
			'YỲỶỸÝỴ',
		];
		for (var i = 0; i < AccentsMap.length; i++) {
			var re = new RegExp('[' + AccentsMap[i].substr(1) + ']', 'g');
			var char = AccentsMap[i][0];
			str = str.replace(re, char);
		}

		str = str
			.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
			.replace(/\s+/g, '-') // collapse whitespace and replace by -
			.replace(/-+/g, '-'); // collapse dashes

		return str;
	},
	getKey: function (str) {
		str = str.replace(/^\s+|\s+$/g, ''); // trim
		str = str.toLowerCase();

		// remove accents, swap ñ for n, etc
		// var from = "ÀÁẠẢÃẦẤẬẨÂĂẮẰẶẴÄÅĄàáâãäåąÒÓÔÕÕÖØòóôõöøÈÉÊËĘèéêëðęÇĆçćÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠŚšśŸÿýŽŹŻžźżŁłŃń·/_,:;";
		// var to = "AAAAAAAaaaaaaaOOOOOOOooooooEEEEEeeeeeeCCccDIIIIiiiiUUUUuuuuNnSSssYyyZZZzzzLlNn------";
		// for (var i = 0, l = from.length; i < l; i++) {
		//   str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
		// }

		var AccentsMap = [
			'aàảãáạăằẳẵắặâầẩẫấậ',
			'AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ',
			'dđ',
			'DĐ',
			'eèẻẽéẹêềểễếệ',
			'EÈẺẼÉẸÊỀỂỄẾỆ',
			'iìỉĩíị',
			'IÌỈĨÍỊ',
			'oòỏõóọôồổỗốộơờởỡớợ',
			'OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ',
			'uùủũúụưừửữứự',
			'UÙỦŨÚỤƯỪỬỮỨỰ',
			'yỳỷỹýỵ',
			'YỲỶỸÝỴ',
		];
		for (var i = 0; i < AccentsMap.length; i++) {
			var re = new RegExp('[' + AccentsMap[i].substr(1) + ']', 'g');
			var char = AccentsMap[i][0];
			str = str.replace(re, char);
		}

		str = str
			.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
			.replace(/\s+/g, '') // collapse whitespace and replace by -
			.replace(/-+/g, ''); // collapse dashes

		return str;
	},
	formatPhone: function (phone: string) {
		if (!phone) return '';
		if (phone === '.') return '';
		let _phone = phone.match(/\d+/g).join('');
		const { length } = _phone;
		const breakPoint = [4, 7];
		if (length > 10) {
			_phone = _phone.substring(0, 10);
		}
		if (length > breakPoint[1]) {
			return `${_phone.substring(0, breakPoint[0])}.${_phone.substring(
				breakPoint[0],
				breakPoint[1]
			)}.${_phone.substring(breakPoint[1], length)}`;
		}
		if (length > breakPoint[0] && length <= breakPoint[1]) {
			return `${_phone.substring(0, breakPoint[0])}.${_phone.substring(
				breakPoint[0],
				length
			)}`;
		}
		return _phone;
	},
	getUnique: function (arr, index) {
		const unique = arr
			.map((e) => e[index])

			// store the keys of the unique objects
			.map((e, i, final) => final.indexOf(e) === i && i)

			// eliminate the dead keys & store unique objects
			.filter((e) => arr[e])
			.map((e) => arr[e]);

		return unique;
	},
	localStorageSetItem: function (key, value) {
		return Promise.resolve().then(function () {
			localStorage.setItem(key, value);
		});
	},
	localStorageGetItem: function (key) {
		return Promise.resolve().then(function () {
			return localStorage.getItem(key);
		});
	},
};
