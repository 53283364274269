const validateInputPrice = (price) => {
	const regexp = /^[0-9]/;
	const checkingResult = regexp.test(price);
	if (checkingResult) {
		return true;
	}
	return false;
};

const momoPaymentCodeValid = (paymentCode) => {
	if (paymentCode.replace('MM', '').length !== 18) {
		return false;
	}
	if (paymentCode.length === 20 && paymentCode.slice(0, 2) !== 'MM') {
		return false;
	}
	if (isNaN(paymentCode.replace('MM', ''))) {
		return false;
	}
	return true;
};

function vnPhoneNumber(phoneNumber) {
	const regex = /^(09|03|07|08|05)\d{8}$/;
	return regex.test(phoneNumber);
}

const nameReg =
	/^[a-z0-9A-Z_ÀÁÂÃÈÉÊẾÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêếìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹý ]{0,20}$/;

export default {
	validateInputPrice,
	momoPaymentCodeValid,
	nameReg,
	vnPhoneNumber
};
