import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import { Mocks } from 'utils';

const { SubMenu } = Menu;

export default function MainMenu() {
	const location = useLocation();
	const [selectedKeys, setSelectedKeys] = useState([location.pathname]);

	useEffect(() => {
		setSelectedKeys(['/' + location.pathname.split('/')[1]]);
	}, [location.pathname]);

	return (
		<Menu theme="dark" mode="inline" selectedKeys={selectedKeys}>
			{Mocks.MENUS.menus.map((item) => {
				if (item.nested.length) {
					return (
						<SubMenu key={item.key} icon={item.icon} title={item.title}>
							{item.nested.map((nestedItem) => {
								return (
									<Menu.Item key={nestedItem.key} style={{ paddingLeft: 35 }}>
										<Link
											to={nestedItem.path}
											target={nestedItem.path[0] === '/' ? '_self' : '_blank'}
										>
											{nestedItem.title}
										</Link>
									</Menu.Item>
								);
							})}
						</SubMenu>
					);
				}
				return (
					<Menu.Item key={item.key} icon={item.icon}>
						<Link to={item.path}>{item.title}</Link>
					</Menu.Item>
				);
			})}
		</Menu>
	);
}
