import React, { Suspense } from 'react'
import { useRoutes } from 'react-router-dom'
import Page404 from '../pages/errors/404'
import Page403 from '../pages/errors/403'
import Page500 from '../pages/errors/500'
import ScrollToTop from 'components/SrollToTop'
import Loading from '../components/Loading'
import { useAppSelector } from 'redux/store'
import RecordMobile from 'pages/ai-record/Webview/RecordMobile'

const AuthorizationPage = (element, url: string) => {
  const urlAccess = useAppSelector((state) => state.user.urlAccess)
  if (urlAccess) {
    return urlAccess.find((i) => url.includes(i.path)) ? element : <Page403 />
  }
}

function DefaultRouter() {
  const routes = useRoutes([
    {
        path: '/record-mobile/:id',
        element: <RecordMobile />
    },
  ])
  return (
    <>
      <ScrollToTop />
      <Suspense fallback={<Loading />}>{routes}</Suspense>
    </>
  )
}

export default DefaultRouter
