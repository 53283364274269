import { Spin } from 'antd';
import React from 'react';

export default function Loading() {
	return (
		<div
			style={{
				minHeight: '40vh',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'end',
			}}
		>
			<Spin />
		</div>
	);
}
