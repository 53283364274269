import React, { useMemo } from 'react';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';
import { orEmpty } from 'utils/selector';

function Page403(props) {
	const { state } = props;

	return (
		<Result
			status="403"
			title="403"
			subTitle="Xin lỗi, bạn không có quyền truy cập trang này."
			extra={
				<Button type="primary">
					<Link to={'/'}>Quay lại trang chính</Link>
				</Button>
			}
		/>
	);
}

export default Page403;
