import { RequestManager } from "utils";
import { listMenu } from "utils/requestManager/Author";

const getUser = (params?: any) => {
  return RequestManager.authorV2.get("/api/user", { params });
};

const getPermissionUser = (params = null) => {
  return RequestManager.author.get(`/api/permissions/actions`, { params });
};

const changeCustomerPhoneInBill = (params) => {
  return RequestManager.checkoutServiceMSA.put(
    "/api/v1/app-fc/update-customer-phone",
    params
  );
};


const getListMenuAccess = () => {
  return listMenu.get(`/api/menus?Keyword=checkout`);
};

export default {
  getUser,
  getPermissionUser,
  getListMenuAccess,
  changeCustomerPhoneInBill,
};
