import React, {
  useMemo,
  useState,
  useCallback,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  PlayCircleOutlined,
  PauseCircleOutlined,
  ForwardOutlined,
  BackwardOutlined,
} from "@ant-design/icons";
import { Spin, Flex, Radio, RadioChangeEvent } from "antd";
import { useWavesurfer } from "@wavesurfer/react";
import Timeline from "wavesurfer.js/dist/plugins/timeline.esm.js";
import ZoomPlugin from "wavesurfer.js/dist/plugins/zoom.esm.js";

const AudioViewWebview = forwardRef(
  (
    {
      updateCurrentTime,
      audioSrc,
      selectTime,
      onChangeAudioSource,
      audioSources,
      hasShow,
      onReady,
    }: {
      updateCurrentTime;
      audioSrc;
      selectTime;
      onChangeAudioSource;
      audioSources;
      hasShow;
      onReady;
    },
    ref
  ) => {
    const [loading, setLoading] = useState(true);
    const [isWavesurferReady, setIsWavesurferReady] = useState(false);
    const containerRef = useRef(null);

    // wavesurfer player
    const { wavesurfer, isPlaying, currentTime } = useWavesurfer({
      container: containerRef,
      height: 400,
      waveColor: "#4096ff",
      progressColor: "#d1e6ff",
      cursorColor: "#ddd5e9",
      barWidth: 4,
      barGap: 5,
      barRadius: 2,
      minPxPerSec: 100,
      url: audioSrc,
      hideScrollbar: true,
      plugins: useMemo(
        () => [
          Timeline.create(),
          ZoomPlugin.create({
            scale: 0.5,
            maxZoom: 100,
          }),
        ],
        []
      ),
    });

    const onPlayPause = useCallback(() => {
      wavesurfer && wavesurfer.playPause();
    }, [wavesurfer]);

    const forwardButton = useCallback(() => {
      wavesurfer && wavesurfer.skip(5);
    }, [wavesurfer]);

    const backButton = useCallback(() => {
      wavesurfer && wavesurfer.skip(-5);
    }, [wavesurfer]);

    useEffect(() => {
      if (wavesurfer) {
        wavesurfer.on("ready", () => {
          setIsWavesurferReady(true);
          onReady();
        });
      }
    }, [wavesurfer]);

    useEffect(() => {
      updateCurrentTime(currentTime);
    }, [currentTime]);

    useEffect(() => {
      if (wavesurfer) {
        wavesurfer.seekTo(selectTime / wavesurfer.getDuration());
      }
    }, [selectTime]);

    useImperativeHandle(ref, () => ({
      onPlayPause,
      forwardButton,
      backButton,
      isPlaying,
      wavesurfer,
      containerRef,
      isWavesurferReady,
    }));

    return (
      <div className={hasShow ? "" : "hidden"}>
        {containerRef ? <div ref={containerRef} /> : <p>Audio loading...</p>}
        {audioSources && audioSources.length > 1 ? (
          <div style={{ marginTop: 30, alignSelf: "center" }}>
            <Flex vertical gap="middle">
              <Radio.Group
                defaultValue={audioSources[audioSources.length - 1]}
                buttonStyle="solid"
                value={audioSrc}
                onChange={onChangeAudioSource}
              >
                {audioSources.map((source, sourceIndex) => (
                  <Radio.Button
                    value={source}
                    key={`select-audio-to-play-${sourceIndex + 1}`}
                  >
                    {sourceIndex + 1}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </Flex>
          </div>
        ) : null}
      </div>
    );
  }
);

export default AudioViewWebview;
