const removeVietnameseAccent = (str) => {
	str = str.toLowerCase();
	str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
	str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
	str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
	str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
	str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
	str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
	str = str.replace(/đ/g, 'd');
	str = str.replace(
		/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
		' '
	);
	str = str.replace(/ + /g, ' ');
	str = str.trim();
	return str;
};

const formatPrice = (price) => {
	price = Math.round(price);
	return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatPriceWithKCharacter = (price = 0) => {
	return `${price >= 1000 ? price.toString().slice(0, -3) : price}K`;
};

const lowercaseFirstLetter = (string) => {
	return string.charAt(0).toLowerCase() + string.slice(1);
};

const PAYMENT_METHOD = [
	{ id: 1, type: 'CASH', text: 'Tiền mặt', ic: process.env.REACT_APP_ICON_PAYMENT_CASH },
	{ id: 2, type: 'BANKING', text: 'Thẻ (online)', ic: process.env.REACT_APP_ICON_PAYMENT_CARD },
	{ id: 3, type: 'TOPUP', text: 'Topup', ic: process.env.REACT_APP_ICON_PAYMENT_TOPUP },
];

const PAYMENT_METHOD_ONLINE_TYPE = [
	{
		onlinePaymentType: 1,
		onlinePaymentTypeName: 'Máy POS',
		color: '#666664',
	},
	{
		onlinePaymentType: 2,
		onlinePaymentTypeName: 'Moca',
		color: '#0597DC',
	},
	{
		onlinePaymentType: 3,
		onlinePaymentTypeName: 'Vnpay',
		color: '#004A9B',
	},
	{
		onlinePaymentType: 4,
		onlinePaymentTypeName: 'QR-CODE MSB',
		color: '#E41B2B',
	},
	{
		onlinePaymentType: 5,
		onlinePaymentTypeName: 'ShopeePay',
		color: '#ED4C2F',
	},
	{
		onlinePaymentType: 6,
		onlinePaymentTypeName: 'Momo',
		color: '#AE2170',
	},
	{
		onlinePaymentType: 7,
		onlinePaymentTypeName: 'Đơn online',
		color: '#fbd100',
	},
	{
		onlinePaymentType: 8,
		onlinePaymentTypeName: 'MBB',
		color: '#1E01CE',
	},
];

const toLowerKeysObjOfArray = (array) => {
	return array.map((item) =>
		Object.keys(item).reduce((accumulator, key) => {
			accumulator[lowercaseFirstLetter(key)] = item[key];
			return accumulator;
		}, {})
	);
};

const roundDecimal = (num, decimal = 2) => {
	if (!num) return 0;
	return num > 0
		? Math.floor(num * Math.pow(10, decimal)) / Math.pow(10, decimal)
		: Math.ceil(num * Math.pow(10, decimal)) / Math.pow(10, decimal);
};

export default {
	removeVietnameseAccent,
	formatPrice,
	lowercaseFirstLetter,
	toLowerKeysObjOfArray,
	PAYMENT_METHOD,
	roundDecimal,
	PAYMENT_METHOD_ONLINE_TYPE,
};
