import { RequestManager } from "utils";

const getListBillRevenue = (params?: any) => {
  return RequestManager.checkoutMSA.get("/api/v1/checkout/bill-histories", {
    params,
  });
};

const exportData = (params?: any) => {
  return RequestManager.checkoutMSA.get(
    "/api/v1/checkout/bill-listings-data-excel",
    {
      params,
    }
  );
};

const exportAllData = (params?: any) => {
  return RequestManager.checkoutMSA.get(
    "/api/v1/checkout/bill-listings-data-excel-misa",
    { params }
  );
};
const deleteBill = (params?: any) => {
  return RequestManager.checkoutServiceMSA.delete("/api/v1/checkout/billing", {
    params,
  });
};

const getListReasonDeleteBill = () => {
  return RequestManager.timeline.get("/api/booking/delete-reasons?isBill=true");
};

const getNotification = (params?: any) => {
  return RequestManager.Notification.get(
    "/api/mongo-notification/sale-detail-by-user-date",
    {
      params,
    }
  );
};
// lấy tên kh theo sđt
const getCustomerInfo = (customerPhone: string, brandName: string) => {
  return RequestManager.CustomerViewService.get(`/api/v1/checkout/info?Phone=${customerPhone}&brandName=${brandName}`);
};

const postCreateSellProductBill = (params?: any) => {
  return RequestManager.checkoutServiceMSA.post("/api/v1/checkout/billing", 
    params,
  );
};
// thêm khách hàng
const postCreateCustomerName = (params?: any) => {
  return RequestManager.CustomerViewService.post(
    "/api/v1/checkout/customer",
    params
  );
};
// sửa thên khách hàng
const putUpdateCustomerName = (params?: any) => {
  return RequestManager.CustomerViewService.put(
    "/api/v1/checkout/customer",
    params
  );
};

const getNewBillPending = (params) => {
  return RequestManager.checkoutMSA.get("/api/v1/checkout/bill-pending", {
    params,
  });
};

const unassignBillPending = (params) => {
  return RequestManager.checkoutServiceMSA.put(
    "/api/v1/checkout/unassign-st-sk",
    params
  );
};

export default {
  getListBillRevenue,
  exportData,
  exportAllData,
  deleteBill,
  getListReasonDeleteBill,
  getNotification,
  getCustomerInfo,
  postCreateSellProductBill,
  postCreateCustomerName,
  getNewBillPending,
  unassignBillPending,
  putUpdateCustomerName,
};
