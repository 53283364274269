import React, { useEffect, useState } from 'react';
import './App.scss';
import config from 'configs/env';
import MainDashboard from './pages';
import { SSO } from '@30shine-fe/sso-erp';
import axios from 'axios';
import { users } from 'api';
import { useDispatch } from 'react-redux';
import { setUrlAccess } from 'redux/slices/userSlice';
import queryString from 'query-string';
import DefaultLayout from 'layouts/DefaultLayout';

const sso = new SSO(process.env.REACT_APP_ENV || 'test');

function App() {
	const dispatch = useDispatch()

	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [isWebview, setIsWebview] = useState(false);

	const fetchCredentials = async () => {
		const searchParams = queryString.parse(window.location.search);
		
		if (searchParams?.token) {
			fetchAuthorization()
			localStorage.setItem('AccessToken', searchParams.token.toString());
			setIsAuthenticated(true);
			return;
		}
		if(searchParams?.isWebview) {
			setIsWebview(true)
		}
		try {
			const credentials = await sso.ssoLogin();
			if (credentials) {
				localStorage.setItem('AccessToken', credentials.AccessToken);
				localStorage.setItem('RefreshToken', credentials.RefreshToken);
				localStorage.setItem('IdToken', credentials.IdToken);
				const request = axios.create({
					baseURL: config.base_authenticator_v2_url,
				});
				request.defaults.headers.common['Authorization'] =
					'Bearer ' + credentials.AccessToken;
				request.interceptors.response.use(
					(response) => {
						return response;
					},
					async (error) => {
						console.log(error);
						const originalRequest = error.config;
						if (error.response.status === 401) {
							let data = await sso.refreshToken();
							originalRequest.headers = {
								Authorization: 'Bearer ' + data.AccessToken,
							};
							localStorage.setItem('AccessToken', data.AccessToken);
							localStorage.setItem('RefreshToken', data.RefreshToken);
							localStorage.setItem('IdToken', data.IdToken);
							return request(originalRequest);
						}
						return Promise.reject(error);
					}
				);
				fetchAuthorization()
			}
		} catch (e) {
			console.log(e);
		}
	};
	
	const fetchAuthorization = async () => {
		try {
			const response = await users.getListMenuAccess()
			if (response?.data?.errorCode === 0) {
				dispatch(setUrlAccess(response?.data?.data))
				setIsAuthenticated(true);
			}
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		fetchCredentials();
	}, []);

	return <div className="App"> {isAuthenticated ?
		<>
		  {isWebview ?
			<DefaultLayout />
			:
			<MainDashboard />
		  }
		</>
		:
		null}</div>;
}

export default App;
