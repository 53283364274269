import React from "react";
import { Result, Button } from "antd";
import { Link } from "react-router-dom";

export default function Page404() {
    return (
        <Result
            status="404"
            title="404"
            subTitle="Xin lỗi, trang bạn truy cập không tồn tại."
            extra={<Button type="primary"><Link to={"/"} >Quay lại trang chính</Link></Button>}
        />
    )
}