import { Button } from "antd";
import { analyzeAudioWithClaudeAi } from "api/aiRecord";
import { useEffect, useRef, useState } from "react";

const ClaudeAiReviewResultsWebview = ({
  data,
  aiRecordId,
  hasShow,
}: {
  data: any;
  aiRecordId: number;
  hasShow: boolean;
}) => {
  const [dataConverted, setDataConverted] = useState([]);
  const [more, setMore] = useState("");
  const [isRunClaudeAiAnalyze, setIsRunClaudeAiAnalyze] = useState(false);

  const descriptions = {
    consulting_and_sales_skills: "Khả năng tư vấn, sale",
    skills: "Khả năng tư vấn, sale",
    clarity_on_waiting_time: "Nói rõ về thời gian chờ đợi",
    clarity_time: "Nói rõ về thời gian chờ đợi",
    clarity_on_product_and_service_pricing: "Nói rõ về giá sản phẩm dịch vụ",
    clarity_pricing: "Nói rõ về giá sản phẩm dịch vụ",
    more: "Gợi ý của AI về việc có thể làm tốt hơn cho lần sau",
  };

  function generateData(data) {
    setDataConverted(
      data
        .filter((el) => el.ReviewKey !== "more")
        .map((el) => {
          return {
            ...el,
            ReviewDescription: descriptions[el.ReviewKey],
          };
        })
    );

    const moreItemFound = data.find((el) => el.ReviewKey === "more");
    if (moreItemFound) setMore(moreItemFound.ReviewValue);
  }

  async function runClaudeAiAnalyze() {
    setIsRunClaudeAiAnalyze(true);
    const rs = await analyzeAudioWithClaudeAi(aiRecordId);
    generateData(rs);
    setIsRunClaudeAiAnalyze(false);
  }

  useEffect(() => {
    generateData(data);
  }, [data]);

  if (!hasShow) {
    return null;
  }

  return dataConverted.length > 0 ? (
    <div className="max-h-96 overflow-y-auto">
      <div className="min-w-full bg-white shadow-md rounded-lg mb-3 ">
        {/* Đặt chiều cao tối đa cho vùng cuộn của bảng */}
        <table className="min-w-full bg-white">
          <thead>
            <tr className="bg-blue-gray-100 text-gray-700">
              <th className="py-3 px-4 text-left">Mô tả</th>
              <th className="py-3 px-4 text-left">Điểm</th>
            </tr>
          </thead>
          <tbody className="text-blue-gray-900">
            {dataConverted.map((el, index) => (
              <tr key={index} className="border-b border-blue-gray-200">
                <td className="py-3 px-4">{el.ReviewDescription}</td>
                <td className="py-3 px-4 text-right">{el.ReviewValue}/10</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Khu vực hiển thị gợi ý */}
      <div className="min-w-full bg-white shadow-md rounded-lg py-3 px-4">
        <h2 className="font-bold">
          Gợi ý của AI về việc có thể làm tốt hơn cho lần sau:
        </h2>
        {more.split("\n").map((item, index) => (
          <span key={index}>
            {item}
            <br />
          </span>
        ))}
      </div>
    </div>
  ) : (
    <div className="bg-white shadow-md rounded-lg p-3 h-full">
      <Button
        type="primary"
        onClick={runClaudeAiAnalyze}
        loading={isRunClaudeAiAnalyze}
      >
        {isRunClaudeAiAnalyze ? "Đang phân tích" : "Phân tích audio này"}
      </Button>
    </div>
  );
};

export default ClaudeAiReviewResultsWebview;
